.container {
    padding: 78px;
    text-align: center;
  }
  
  .header {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .timeList {
    list-style: none;
    padding: 0;
    color: #fff
  }
  
  .timeItem {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #000;
    border-radius: 5px;
    background-color: #db5959;
    color: #000 !important;
  }
  /* src/index.css or src/App.css */
/* Import the font using @font-face */
@font-face {
  font-family: 'Waltograph';
  src: url('../../waltograph42.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

/* Apply the font to the header and other elements as needed */
.header {
  font-family: 'Waltograph', sans-serif;
  font-size: 2.5rem;
  color: #333;
}

.container {
  font-family: 'Waltograph', sans-serif;
}
.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 2rem;
  cursor: pointer;
  color: white;
}

.back-button:hover {
  color: #888; /* Change color on hover to indicate it's clickable */
}

.footer {
  position: fixed !important;
  bottom: 10px;
  left: 10px;
  font-size: 14px;
}

.footer a {
  color: #0000EE; /* Standard link color */
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}