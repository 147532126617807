.container {
    padding: 20px;
    text-align: center;
  }
  
  .header {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .rideList {
    list-style: none;
    padding: 0;
  }
  
  .rideItem {
    margin: 5px auto;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 1px;
    background-color: #f9f9f9;
    font-size: 0.9em; /* Reduce font size */
    width: 50%;
  }
  
  .link {
    text-decoration: none;
    color: #333;
  }
  .back-button {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 2rem;
    cursor: pointer;
    color: white;
  }
  
  .back-button:hover {
    color: #888; /* Change color on hover to indicate it's clickable */
  }

  .footer {
    position: fixed !important;
    bottom: 10px;
    left: 10px;
    font-size: 14px;
  }
  
  .footer a {
    color: #0000EE; /* Standard link color */
    text-decoration: none;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }